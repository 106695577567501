<template>
    <div class="ma-0 "> 
           

        <!-- //---------------------DIALOG -->
        <div>  
            <!-- RECORD EXPENSE -->
            <v-dialog
                v-model="dialog_record_expenses"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="800px"
                transition="dialog-transition" >
                <v-card rounded="lg" v-if="dialog_record_expenses"> 
                    <v-card-title class="primary">
                        <div class="font-weight-bold b2--text">RECORD EXPENSE</div> 
                        <v-spacer></v-spacer> 
                         <v-btn @click="dialog_record_expenses=!dialog_record_expenses" icon dark><v-icon>close</v-icon></v-btn>
                    </v-card-title>
                    <v-card-text>
                        <br> 
                        <!-- <div class="mb-2">Order details  </div> -->
                        <form @submit.prevent="CREATE_NEW_EXPENSE(input,cashup)"> 
                            <mbs-input-fields class="mx-4 my-3"
                                v-model="input"
                                :outlined="true"
                                :dense="false"
                                py="1" px="1"
                                :field_items="DATA.FIELDS.expenses"
                                :select_items="DataSource"  
                                />  
                            <v-layout row wrap class="ma-0 " >
                                <v-btn @click="dialog_record_expenses = false" large  
                                    :loading="inputLoading"
                                    class="px-9 mt-3 secondary"  color="primary" height="60"  >
                                    <div class="not-f5">Cancel</div>  
                                </v-btn>   
                                <v-flex >
                                    <v-btn type="submit" large  
                                        :loading="inputLoading"
                                        class="mt-3 ml-1"  color="primary" height="60" width="100%">
                                        <div class="not-f5">Save Expense</div>  
                                    </v-btn>   
                                </v-flex> 
                                <!-- <v-flex sm4> 
                                    <v-menu v-if="MBS.actions.SIZE(ItemOrderOptionsData)>0"
                                        v-model="menu_send_and_print_option" 
                                        :close-on-content-click="false" 
                                        offset-y  offset-overflow
                                        :nudge-width="90" rounded="xl">

                                        <template v-slot:activator="{ on }">   
                                             
                                            <v-btn large  v-show="false" type="submit"
                                                :loading="inputLoading" ref="ref_btn_send_and_print"
                                                class="mt-3 "  color="secondary" height="60" width="99%">
                                                <div class="not-f5">Send & Print</div>  
                                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                            </v-btn> 
                                            <v-btn large  v-on="on"
                                                :loading="inputLoading" 
                                                class="mt-3 "  color="secondary" height="60" width="99%">
                                                <div class="not-f5">Send & Print</div>  
                                                <v-icon class="ml-1 mr-1">arrow_drop_down</v-icon>
                                            </v-btn> 
                                        </template>  
                                        <v-card>
                                            <v-card-title primary-title class="secondary white--text">
                                                Select Print Option
                                            </v-card-title>
                                            <v-list>
                                                <v-list-item type="submit"  @click="SEND_AND_PRINT_NEW_ORDER('print_docket_and_bill')">
                                                    Send & Print Both Docket & Bill
                                                </v-list-item> 
                                                <v-list-item type="submit" @click="SEND_AND_PRINT_NEW_ORDER('print_docket')">
                                                    Send & Print Docket
                                                </v-list-item>
                                                <v-list-item type="submit" @click="SEND_AND_PRINT_NEW_ORDER('print_bill')">
                                                    Send & Print Bill
                                                </v-list-item> 
                                            </v-list>
                                        </v-card>
                                    </v-menu> 
                                </v-flex>  -->
                            </v-layout>  
                        </form>
                    </v-card-text> 
                </v-card> 
            </v-dialog> 
            
            <!-- VIEW SALES HISTORY -->
            <v-dialog
                v-model="dialog_view_sales"
                scrollable   
                :persistent="false" :overlay="false"
                max-width="1100px"
                transition="dialog-transition" >
                <v-card rounded="lg" > 
                    <mbs-item-manager
                        :table_title="'My History'"
                        table_title_icon="mdi-clipboard-text-clock"
                        :add_vertical_lines="true"
                        :top_label="false"
                        :filter_date="true"
                        :filter_date_value="'local_created_at'"
                        :filter_default_days="'90'"
                        :filters="FILTERS"
                        :row_click="true"
                        :show_select="false"
                        :row_click_action="false" 

                        :name_items="NAME_ITEMS"
                        :name_item="NAME_ITEM"
                        :value_items="VALUE_ITEMS"
                        :value_item="VALUE_ITEM"
                        :field_items="FIELD_ITEMS"
                        :header_items="HEADER_ITEMS" 
                        :data_items="SortedBothSaleOrders"   
                        :select_items="DataSource"   
                        :is_joined="true"  
                        :path_item="SALES_PATH"   
                        :total_item_values="TOTAL_ITEM_VALUES"  

                        :table_actions="table_actions" 
                        @SELECT_TABLE_ACTION="TABLE_ACTION"
                        @SELECT_ITEM_ACTION="ITEM_ACTION"
                    />
                </v-card> 
            </v-dialog>  
          

            <div v-if="dialog_doc_show"> 
                <mbs-dialog-doc-show
                    v-model="dialog_doc_show" 
                    :title="input_show_doc.title"
                    :data="input_show_doc.data"
                    :data_key="input_show_doc.data_key"
                    :document_type="input_show_doc.document_type"
                    :height="input_show_doc.height"
                    :top_header="input_show_doc.top_header"
                    :bottom_header="input_show_doc.bottom_header"
                />  
            </div>

            <!-- //print -->
            <div v-if="auto_print.printing"> 
                <mbs-auto-print 
                    @finish_print="FINISH_PRINT"
                    :show="true"  
                    :auto_print="auto_print.auto"
                    :auto_printer_name="auto_print.auto_printer_name"
                    :com_printer="auto_print.com_printer"  
                    :data="auto_print.print_data"
                    :type="auto_print.print_type"
                    :title="auto_print.print_title"
                    :printing_action="auto_print.printing_action"
                    :display_option="auto_print.display_option" 
                    />  
            </div> 
        </div>
    </div>
</template> 
<script>
let PAGE_NAME = 'CASHUP-EXPENSES'
import DATA from "../../../plugins/DATA"
import DB from "../../../plugins/DB"
import {mapState } from "vuex" 
import { mdiReceiptTextArrowRight } from '@mdi/js';
export default { 
    props:['cashup',''],
    data(){
        return{  
            body_height:0,
             
            input:{}, 
            inputLoading:false,  
            DATA:DATA,   
            dialog_record_expenses:false,   
            dialog_view_sales:false,  
            dialog_doc_show:false, 

            auto_print:{
                auto:false,
                printing:false, 
                print_data:null,
                print_type:null,
                com_printer:false, 
                print_title:null, 
                auto_printer_name:null,
                printing_action:null, 
                display_option:'',  
            },
            NAME_ITEMS:DATA.ITEMS.SALES.names,
                NAME_ITEM:DATA.ITEMS.SALES.name,
                VALUE_ITEMS:DATA.ITEMS.SALE_ORDERS.values,
                VALUE_ITEM:DATA.ITEMS.SALE_ORDERS.value,  

                FIELD_ITEMS:[
                    {type:"number",name:"code",value:"code",label:"Code",required:true,sm:12,md:12}, 
                    {type:"text",name:"name",value:"name",label:"Name",required:true,sm:12,md:12},   
                ],     
                table_actions:[  
                    {type:"btn",action:"sales_return",color:"secondary", outlined:false,  text:"Sales Return"},  
                    {type:"action",btn:true,icon:"mdi-refresh",action:"refresh",color:"secondary", outlined:false,  text:"Refresh"},   
                    {type:"action",icon:"mdi-microsoft-excel",action:"export_excel",color:"secondary", outlined:false,  text:"Export to Excel"},  
                ],
                TOTAL_ITEM_VALUES:[ 
                    //  {name:'Total Items',value:'sold_quantity'},

                    {name:'Total Cost Price',value:'sold_cost_price',show_value:'sold_cost_price_',money:true},
                    {name:'Total Price',value:'total_sold_price',show_value:'total_sold_price_',money:true}, 
                    {name:'Total Sold Price',value:'sum_amount',show_value:'sum_amount_',money:true},
                    {name:'VAT',value:'total_vat',show_value:'total_vat_',money:true},
                ], 
                dialog_doc_show:false,
                input_show_doc:{
                    // title:"INVOICE..",
                    top_header:true
                },
            
        } 
    }, 
    created() {
        try {
            this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);      
            this.MBS.events.$on('ON_VIEW_SALES', this.ON_VIEW_SALES);   
 
        } catch (error) {
            this.MBS.actions.error({
                error:error,
                from:'created',
                page:PAGE_NAME, 
            }) 
        }
    },
    mounted(){  
    },
    computed:{
        route(){
            return this.$route
        },
        vs(){

            return this.$vuetify.breakpoint
        }, 
        vsh(){
            return this.vs.height
        },  
        SALES_PATH(){  
            let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.sale_orders,true) 
            return path
        }, 
        ...mapState({
            loading:state=>state.load.loading,
            processes:state=>state.load.processes,
            responses:state=>state.load.responses,
        }),
        ...mapState({
            us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
            ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            SELECTED_COMPANY: state=> state.items['SELECTED_COMPANY'], 
        }),
        ...mapState({
            SaleOrders: state=> state.items[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()],
            JoinedSaleOrders: state=> state.join[(DATA.ITEMS.SALE_ORDERS.values).toUpperCase()], 
            LocalSaleOrders: state=> state.items[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            JoinedLocalSaleOrders: state=> state.join[(DATA.ITEMS.LOCAL_SALE_ORDERS.values).toUpperCase()],
            DayShifts: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()], 
            JoinedDayShifts: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS.values).toUpperCase()],  
            DayShiftsSales: state=> state.items[(DATA.ITEMS.DAYS_SHIFTS_SALES.values).toUpperCase()], 
            JoinedDayShiftsSales: state=> state.join[(DATA.ITEMS.DAYS_SHIFTS_SALES.values).toUpperCase()], 
        
            Customers: state=> state.items[(DATA.ITEMS.CUSTOMERS.values).toUpperCase()],
            PaymentMethods: state=> state.items[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
            JoinedPaymentMethods: state=> state.join[(DATA.ITEMS.PAYMENT_METHODS.values).toUpperCase()],
            DefaultPaymentMethod: state=> state.app[(DATA.ITEMS.DEFAULT_PAYMENT_METHODS.values).toUpperCase()],
            Departments: state=> state.items[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()],
            JoinedDepartments: state=> state.join[(DATA.ITEMS.ITEM_DEPARTMENTS.values).toUpperCase()], 
            Outlets: state=> state.items[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()],
            JoinedOutlets: state=> state.join[(DATA.ITEMS.STOCK_OUTLETS.values).toUpperCase()], 
            Cashups: state=> state.items[(DATA.ITEMS.CASHUPS.values).toUpperCase()],
            JoinedCashups: state=> state.join[(DATA.ITEMS.CASHUPS.values).toUpperCase()],
                  
        }), 
        LocalCashup(){   
            let cashups = this.LocalCashups
            let local_cashup = cashups?cashups.find(item=>{
                return !item.closed
            }):null 
            return local_cashup
        },
        PaymentMethodsData(){ 
                let DefaultPaymentMethod = this.DefaultPaymentMethod 
                let PaymentMethods = this.PaymentMethods
                let JoinedPaymentMethods = this.JoinedPaymentMethods
                let items = JoinedPaymentMethods?JoinedPaymentMethods:PaymentMethods 
                if(!items){return DefaultPaymentMethod}
                let list = [...DefaultPaymentMethod,...items]  
                return list
            },  
            DepartmentsData(){ 
                let Departments = this.Departments
                let JoinedDepartments = this.JoinedDepartments
                let items = JoinedDepartments?JoinedDepartments:Departments 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("name",true)) 
                return list
            },  
            SalesOrdersData(){ 
                let SalesOrders = this.SalesOrders
                let JoinedSalesOrders = this.JoinedSalesOrders
                let items = JoinedSalesOrders?JoinedSalesOrders:SalesOrders
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))  
                return list
            }, 
            OutletsData(){ 
                let Outlets = this.Outlets
                let JoinedOutlets = this.JoinedOutlets
                let items = JoinedOutlets?JoinedOutlets:Outlets 
                return items
            }, 
            DayShiftsData(){ 
                let DayShifts = this.DayShifts
                let JoinedDayShifts = this.JoinedDayShifts
                let items = JoinedDayShifts?JoinedDayShifts:DayShifts  
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))   
                return list 
            },  
            CashupsData(){ 
                let Cashups = this.Cashups
                let JoinedCashups = this.JoinedCashups
                let items = JoinedCashups?JoinedCashups:Cashups 
                return items
            }, 
            DayShiftsCashups(){
                let days = this.DayShiftsData
                if(!days){return null}
                let cashups = []
                days.forEach(element => {
                    if (element.joined_cashups) {
                        cashups = [...cashups,...element.joined_cashups]
                    }
                }); 
                if(!cashups){return null} 
                let list = [...cashups]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))    
                return list 
            },
            Users(){
                let items  =this.$store.getters.getCompanyUsers
                let list = items?[...items]:null
                if(!list){return null}
                list.sort(this.MBS.actions.dynamicSort('created_at',false))
                return list
            }, 
            JoinedUsers(){
                let users = this.Users
                let join = this.MBS.actions.JOIN_USERS(users)
                return join
            }, 
            DataSource(){ 
                let Customers = this.Customers
                let PaymentMethodsData = this.PaymentMethodsData
                let Departments = this.DepartmentsData
                let Outlets = this.OutletsData
                let DayShifts = this.DayShiftsData
                let Cashups = this.DayShiftsCashups
                let SalesOrders = this.SalesOrdersData
                let Tellers = this.JoinedUsers 
                return { 
                    Customers:Customers,
                    PaymentMethods:PaymentMethodsData,
                    Departments:Departments,
                    Outlets:Outlets,
                    DayShifts:DayShifts,
                    Cashups:Cashups,
                    SalesOrders:SalesOrders,
                    Tellers:Tellers,
                }
            },
            FILTERS(){ 
                return [  
                    {
                        type:"autocomplete",value:"payment_method_name",prepend_inner_icon:'mdi-account-cash',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Payment",
                        select:{
                            name:'PaymentMethods',
                            value:'name',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"receipt_number",barcode:true,prepend_inner_icon:'mdi-barcode',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Receipt Number",
                        select:{
                            name:'SalesOrders',
                            value:'receipt_number',
                            text:'receipt_number'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"created_uid",barcode:false,prepend_inner_icon:'mdi-tag-text',clearable:true,filled:false,
                        multiple:true,chips:true,deletable_chips:true,label:"Teller Name",
                        select:{
                            name:'Tellers',
                            value:'uid',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"customer_key",barcode:false,prepend_inner_icon:'mdi-tag-text',clearable:true,filled:false,
                        multiple:true,chips:true,deletable_chips:true,label:"Customer",
                        select:{
                            name:'Customers',
                            value:'key',
                            text:'name'
                        }, 
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"cashup_key",barcode:true,prepend_inner_icon:'mdi-account-cash-outline',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Cashup",
                        select:{
                            name:'Cashups',
                            value:'key',
                            text:'operating_date_'
                        }, 
                        search:{
                            filter:(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                    if(!a||!b){return false}
                                    a = ""+a; b = ""+b
                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                } 
                                let first = index(itemText,queryText)
                                let second = item?index(item.code,queryText):false
                                if(!item){return first} 
                                return first || second 
                            },
                            item_icon:{
                                name:'mdi-account-cash-outline',
                                tile:true
                            },
                            item_title:['till_name'],
                            item_subtitle:['teller_name','key','local_created_at_']
                        },
                        required:false,sm:12,md:6,step_name:"Name"
                    },   
                    {
                        type:"autocomplete",value:"day_shift_key",barcode:true,prepend_inner_icon:'mdi-network-pos',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Day Shift",
                        select:{
                            name:'DayShifts',
                            value:'key',
                            text:'operating_date_'
                        }, 
                        search:{
                            filter:(item, queryText, itemText) => { 
                                let index = (a,b)=>{
                                    if(!a||!b){return false}
                                    a = ""+a; b = ""+b
                                    return a.toLocaleLowerCase().indexOf(b.toLocaleLowerCase()) > -1 
                                } 
                                let first = index(itemText,queryText)
                                let second = item?index(item.code,queryText):false
                                if(!item){return first} 
                                return first || second 
                            },
                            item_icon:{
                                name:'mdi-network-pos',
                                tile:true
                            },
                            item_title:['outlet_name'],
                            item_subtitle:['teller_name','operating_date_']
                        },
                        required:false,sm:12,md:6,step_name:"Name"
                    }, 
                    {
                        type:"autocomplete",value:"outlet_code",barcode:true,prepend_inner_icon:'mdi-sitemap',clearable:true,filled:false,multiple:true,
                        chips:true,deletable_chips:true,label:"Outlet",
                        select:{
                            name:'Outlets',
                            value:'code',
                            text:'name'
                        }, 
                        required:false,sm:12,md:12,step_name:"Name"
                    },    
                ]
            }, 
            HEADER_ITEMS(){
                return [ 
                {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image',color:"primary",size:40,tile:true,show:false},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"Date",value:'local_created_at_',show:true},   
                    {id:0,name:"Payment",value:"payment_method_name",show:true},     
                    {id:0,name:"S. Key",value:'day_shift_key',show:false},   
                    {id:0,name:"C. Key",value:'cashup_key',show:false},      
                    {id:0,name:"Receipt No.",value:"receipt_number_",align:"right",divider:true,show:true},         
                    {id:0,name:"Outlet",value:"outlet_name",show:false},         
                    {id:0,name:"Teller",value:"teller_name",show:false},         
                    {id:0,name:"Till",value:"till_name",show:true},         
                    {id:0,name:"Customer",value:'customer_name',show:true},   
                    {id:0,name:"Items",value:'total_quantity_',align:'right',show:true},   
                    {id:0,name:"Cost Price",value:'total_cost_price_',align:'right',show:false},   
                    {id:0,name:"Discount",value:'all_discounts_',align:'right',show:true},   
                    // {id:0,name:"Sold Price",value:'total_sold_price_',align:'right',show:true},  
                    {id:0,name:"Price",value:'total_sold_price_',align:'right',show:true},    
                    {id:0,name:"Sold Price(+)",value:'sum_amount_',align:'right',show:true},   
                    {id:0,name:"Tax A",value:'taxable_a_',align:'right',show:false},   
                    {id:0,name:"Tax B",value:'taxable_b_',align:'right',show:false},   
                    {id:0,name:"Tax E",value:'taxable_c_',align:'right',show:false},   
                    {id:0,name:"VAT",value:'total_vat_',align:'right',show:false},   
                    {id:0,name:"Given Amount",value:'given_amount_',align:'right',show:false},   
                    {id:0,name:"update",value:'updated_at',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        // {name:"View Sales Report",action:"view_order",icon:"mdi-eye"}, 
                        // {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                        {name:"View Sales Report",action:"view_order",icon:"mdi-eye"}, 
                        {name:"Sales Report Page",action:"view_order_page",icon:mdiReceiptTextArrowRight}, 
                        {name:"Archive Sales Report",action:"archive_item",icon:"mdi-delete"},   
                    ]}, 
                ]
            },  
            DataItems(){ 
                let us = this.us
                let uid = us?us.uid:null 
                let SaleOrders = this.SaleOrders
                let JoinedSaleOrders = this.JoinedSaleOrders
                let items = JoinedSaleOrders?JoinedSaleOrders:SaleOrders 
                if(!items){return null}
                let mys = items.filter(sale=>{
                    return sale.created_uid == uid
                }) 
                let list = [...mys]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false)) 
                return list
            }, 
            DayShiftsSalesData(){
                let DayShiftsSales = this.DayShiftsSales
                let JoinedDayShiftsSales = this.JoinedDayShiftsSales
                let items = JoinedDayShiftsSales?JoinedDayShiftsSales:DayShiftsSales
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",false))  
                return list 
            },
            LocalCashup(){   
                let localCashup = this.MBS.actions.LocalCashup  
                return localCashup
            },
            JoinedLocalCashup(){   
                let localCashup = this.LocalCashup
                let joined = this.MBS.actions.JOIN_CASHUP(localCashup)  
                return joined
            },
            SalesOrders(){
                let DayShiftsData = this.DayShiftsData
                let DayShiftsSalesData = this.DayShiftsSalesData

                let orders = []
                if (DayShiftsData) {
                    for (const day of DayShiftsData) {
                        let sales = day.sale  
                    }
                }
                if (DayShiftsSalesData) {
                    for (const sales of DayShiftsSalesData) {
                        if (sales) {
                            orders = [...orders,sales]
                        } 
                    }
                } 
                return orders 
            },
            AllLocalSaleOrdersData(){  
                const LocalSaleOrders = this.LocalSaleOrders
                const JoinedLocalSaleOrders = this.JoinedLocalSaleOrders
                let items = JoinedLocalSaleOrders?JoinedLocalSaleOrders:LocalSaleOrders  
                if(!items){return null} 
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("local_created_at",true)) 
                return list
            }, 
            BothSaleOrders(){
                let orders = this.DataItems
                let localOrders = this.AllLocalSaleOrdersData 
                if(!orders && !localOrders){return null}
                if(orders && !localOrders){return orders}
                if(!orders && localOrders){return localOrders}

                let both = [...orders]
                localOrders.forEach(element => {
                    let item = orders.find(item=>{
                        return item.key == element.key
                    })
                    if (!item) {
                        both.push(element)
                    } 
                }); 
                return both
            },
            SortedBothSaleOrders(){
                let items = this.BothSaleOrders 
                if(!items){return null}
                let list = [...items]
                list.sort(this.MBS.actions.dynamicSort("key",false))  
                return list
            }
    },
    methods:{  
        TABLE_ACTION(action,item){
            try { 
                let route = this.route
                let fullPath = route?route.fullPath:null 
                if (action == "refresh") { 
                    this.REFRESH_ORDERS()
                }else if (action == "order_list") { 
                    this.CHANGE_ORDER_LIST_VIEW('list')
                }else if (action == "assistant_orders") { 
                    this.CHANGE_ORDER_LIST_VIEW('assistant')
                }else if (action == "customer_orders") { 
                    this.CHANGE_ORDER_LIST_VIEW('customer')
                }else if (action == "table_orders") { 
                    this.CHANGE_ORDER_LIST_VIEW('table')
                }else if (action == "waiter_orders") { 
                    this.CHANGE_ORDER_LIST_VIEW('waiter')
                }else if (action == "delete_all_orders") { 
                    this.CONFIRM_DELETE_ALL_ITEM_ORDER()
                }else if (action == "close") { 
                    this.EXIT_DIALOG()
                }else if (action == "exit") { 
                    this.EXIT_DIALOG()
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'TABLE_ACTION',
                    page:PAGE_NAME, 
                }) 
            } 
        }, 
        ITEM_ACTION(action,item){
            try { 
                let link = "/stock/stock-control/"+(item?item.key:null)
                    const path = this.MBS.actions.COMPANY_LINK(link,true)  

                    let doc_title = null
                    let doc_type = null
                    if (item?.account_type == "on_account") {
                            doc_title = "INVOICE"
                            doc_type = "invoice"
                    } else {
                        doc_title = "RECEIPT"
                        doc_type = "receipt" 
                    }
                    if (action == "view_order_page") {
                        let receipt_key = item?item.key:null
                        this.MBS.actions.go("/sales-receipt/"+receipt_key) 
                    }else if (action == "view_order") {  
                        if (doc_title) { 
                            this.SHOW_DIALOG_DOCUMENT({
                                data:item,
                                doc_title:doc_title,
                                doc_type:doc_type,
                            })
                        }
                    }else if (action == "on_row_clicked") { 
                        if (doc_title) { 
                            this.SHOW_DIALOG_DOCUMENT({
                                data:item,
                                doc_title:doc_title,
                                doc_type:doc_type,
                            })
                        }
                    } if (action == "log") { 
                    }  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'TABLE_ACTION',
                    page:PAGE_NAME, 
                }) 
            }
            
        }, 
        ON_VIEW_SALES(event){
            try { 
                this.dialog_view_sales = true
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'ON_VIEW_SALES',
                    page:PAGE_NAME, 
                }) 
            } 
        },  
        EXIT_DIALOG(){
            try { 
                this.dialog_record_expenses = false   
                this.dialog_view_sales = false   
                 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'TABLE_ACTION',
                    page:PAGE_NAME, 
                }) 
            } 
        }, 
        SHOW_DIALOG_DOCUMENT(payload){
            try {
                let data = {}
                let doc_type = payload?.doc_type
                let doc_title = payload?.doc_title
                if(payload?.data){
                    data = payload?.data
                }
                if(payload?.key){
                    data.key = payload?.key
                }
                if(!this.input_show_doc){
                    this.input_show_doc = {}
                } 
                if(data=={}){return null}
                if (doc_type) { 
                }

                this.input_show_doc.data = {...data,merge_doc_type:"receipt"}
                this.input_show_doc.data_key = data.key
                this.input_show_doc.title = doc_title
                this.input_show_doc.document_type = doc_type?doc_type:"invoice"
                this.dialog_doc_show = true  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'SHOW_DIALOG_DOCUMENT',
                    page:PAGE_NAME, 
                }) 
            }

        },
         
        //-------------[main]
        async DIALOG_YES(action){ 
            try {
                if (action.code) {
                    if (action.code ===PAGE_NAME+'=CONFIRM-SELECT-COMPANY') {
                        if (action.YES) {
                            setTimeout(() => { 
                                this.input.check = true 
                                this.MBS.actions.dialogInput({
                                    show:true,
                                    fixed:true,
                                    title:"CONFIRM",
                                    text:"Enter your password to confirm switching to "+this.MBS.actions.TEXT_UP(action.data.company_name)+".",
                                    btnYes:"CONFIRM",
                                    field:[
                                        {field:"password",label:'Password',type:'password'}
                                    ],
                                    action:{
                                        ...action,
                                        code:PAGE_NAME+"=SELECT-COMPANY",
                                    }
                                })  
                            }, this.MBS.data.ACTION_REFRESH_TIME); 
                        } else {
                            
                        }  
                    } 
                    if (action.code ===PAGE_NAME+'=SELECT-COMPANY') {
                        if (action.YES) {
                            setTimeout(() => { 
                                let password = action.password
                                password = password?this.MBS.crypt.encrypt(password):null 
                                if (!password) { 
                                    this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                }else if (password!==this.ud.p_uid) { 
                                    this.MBS.actions.dialog(null,true,false,"ERROR","Wrong password entered",null,"OK")
                                } else {
                                    this.SELECT_COMPANY(action.data) 
                                }   
                            }, this.MBS.data.ACTION_REFRESH_TIME);    
                        } else {
                            
                        }
                    }   
                } 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'DIALOG_YES',
                    page:PAGE_NAME, 
                }) 
            }  
        },
    },
    beforeDestroy(){
        this.MBS.events.$off('DIALOG_YES', this.DIALOG_YES);       
        this.MBS.events.$off('ON_RECODE_EXPENSE', this.ON_RECODE_EXPENSE);       
        this.MBS.events.$off('ON_VIEW_SALES', this.ON_VIEW_SALES);       
    },
    watch:{   
        LocalCashup(value){ 
        },
         
    }
}
</script> 